import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import { rhythm, scale } from '../utils/typography'
import typeset from '../utils/typeset'
import css from '@emotion/css'

class BlogPostTemplate extends React.Component {
  componentDidMount() {
    const mediumZoom = require('medium-zoom').default
    mediumZoom(document.querySelectorAll('img'))
  }

  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const siteDescription = post.excerpt
    const { previous, next } = this.props.pageContext

    let tags
    let tagsSection
    if (post.fields.tagSlugs) {
      const tagsArray = post.fields.tagSlugs
      tags = tagsArray.map((tag, i) => {
        const divider = i < tagsArray.length - 1 && <span>{' | '}</span>
        return (
          <span key={tag}>
            <Link to={tag}>{post.frontmatter.tags[i]}</Link>
            {divider}
          </span>
        )
      })
      tagsSection = (
        <em
          style={{
            ...scale(-1 / 5),
            display: 'block',
            marginBottom: rhythm(1),
          }}
        >
          Tagged with {tags}
        </em>
      )
    }

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet
            htmlAttributes={{ lang: 'en' }}
            meta={[{ name: 'description', content: siteDescription }]}
            title={`${post.frontmatter.title} | ${siteTitle}`}
          />
        </div>
        <h1 style={{ marginBottom: rhythm(1 / 5) }}>
          {post.frontmatter.title}
        </h1>
        {['blog', 'photo'].includes(post.frontmatter.template) ? (
          <small
            style={{
              display: 'block',
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.date} · by {post.frontmatter.author} ·{' '}
            {post.fields.readingTime.text}
          </small>
        ) : (
          <div style={{ height: '1em' }} />
        )}
        <div
          dangerouslySetInnerHTML={{
            __html: typeset(post.html),
          }}
          css={css`
            hr {
              background: none;
              margin-bottom: ${rhythm(2)};
              &:after {
                content: '· · ·';
                word-spacing: ${rhythm(1)};
                display: block;
                text-align: center;
              }
            }
          `}
        />
        {['blog', 'photo'].includes(post.frontmatter.template) && (
          <div>
            {tagsSection}
            <hr style={{ marginBottom: rhythm(1) }} />

            <div style={{ margin: 'auto' }}>
              <ul
                className="no-bullet"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  listStyle: 'none',
                  padding: 0,
                }}
              >
                <li>
                  {previous && (
                    <div>
                      <Link to={'/blog' + previous.fields.slug} rel="prev">
                        ← {previous.frontmatter.title}
                      </Link>
                      <small style={{ display: 'block', textAlign: 'right' }}>
                        {previous.frontmatter.date}
                      </small>
                    </div>
                  )}
                </li>
                <li>
                  {next && (
                    <div>
                      <Link to={'/blog' + next.fields.slug} rel="next">
                        {next.frontmatter.title} →
                      </Link>
                      <small style={{ display: 'block' }}>
                        {next.frontmatter.date}
                      </small>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        )}
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        author
        date(formatString: "MMMM DD, YYYY")
        tags
        template
      }
      fields {
        tagSlugs
        readingTime {
          text
        }
      }
    }
  }
`
